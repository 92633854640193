import React, { useState, useEffect } from 'react';
import { Accordion, Checkbox, Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'errors/components/ErrorModal';
import { getMyGatedComunities } from 'api-operations/community/member';
import { useLogger } from 'logger/Logger';
const GroupList = styled.div `
    display:flex;
    flex-direction: column;`;
const GroupCheckbox = styled.div `
    margin-top: .5rem;`;
const GroupSelect = ({ setIsOpenCommunity, setCommunities, communities, isOpenCommunity }) => {
    const [myGatedCommunities, setMyGatedCommunities] = useState([]);
    const [gcExpanded, setGcExpanded] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const { logger } = useLogger();
    const { t } = useTranslation('common');
    useEffect(() => {
        logger.info('User - Entered select visibility step');
        getMyGatedCommunitiesList();
    }, []);
    const getMyGatedCommunitiesList = async () => {
        try {
            const myGCommunities = await getMyGatedComunities(true);
            setMyGatedCommunities(myGCommunities);
        }
        catch (error) {
            //
        }
    };
    const handleExpansion = (section) => {
        if (section === 'gc') {
            setGcExpanded(prevState => !prevState);
        }
    };
    const updateGroups = (section, id, name, checked) => {
        if (checked == undefined)
            return;
        switch (section) {
            case 'open':
                setIsOpenCommunity(() => checked);
                setCommunities([]);
                break;
            case 'gc':
                if (checked) {
                    setCommunities(prevState => [...prevState, { id: id, name: name }]);
                }
                else {
                    setCommunities(prevState => prevState.filter(e => e.id !== id));
                }
                break;
        }
    };
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    return React.createElement(React.Fragment, null,
        React.createElement(Form, null,
            React.createElement(Form.Field, null,
                React.createElement(Checkbox, { label: t('jobUpload.openCommunity'), onChange: (e, { checked }) => updateGroups('open', null, null, checked), checked: isOpenCommunity })),
            React.createElement(Accordion, { as: Form.Field, exclusive: false },
                React.createElement(Accordion.Title, { active: gcExpanded, onClick: () => handleExpansion('gc') },
                    React.createElement(Icon, { name: 'dropdown' }),
                    t('jobUpload.myGatedCommunities')),
                React.createElement(Accordion.Content, { active: gcExpanded },
                    React.createElement(GroupList, null, myGatedCommunities !== null
                        && myGatedCommunities.map(({ gatedCommunityName, gatedCommunityId }) => React.createElement(GroupCheckbox, { key: gatedCommunityId },
                            React.createElement(Checkbox, { disabled: isOpenCommunity, label: gatedCommunityName, styles: { marginTop: '.5rem' }, checked: communities.filter(({ id }) => id === gatedCommunityId).length > 0, onChange: (e, { checked }) => updateGroups('gc', gatedCommunityId, gatedCommunityName, checked) }))))))));
};
export default GroupSelect;
