import EditorModule from "../EditorModule";
import { getProgress, getJobSectionProgress, reportWorkSeconds } from "api-operations/editor/editor";
import i18next from "i18next";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { restoreButton as restoreButtonIcon } from "editorNextGen/icons/restore";
import { ancestorOfType, calculateProgress, consumeEvent, sameAsset } from "editorNextGen/utils";
import { secondsToReadableUnits } from "helpers/utils";
import "./style.scss";
import UserService from "UserService";
import { Popup } from "editorNextGen/components/tooltip/Tooltip";
const LOG_TIME_INTERVAL = 30;
export default class ContentEditionModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "ContentEditionModule";
        this.assignmentProgressBar = document.createElement("progress");
        this.sectionProgressBar = document.createElement("progress");
        this.workTimeInterval = null;
        this.duplicateUpdateTimeouts = new Map();
        this._assetEdition = true;
        this.focused = true;
        const { statusBar, sectionHeader, sectionBar } = editor;
        this.statusBarAssignmentAssetsRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.remainingAssets')}: `);
        this.statusBarAssignmentTimeRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.estTimeRemaining')}: `);
        this.statusBarSectionAssetsTotal = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.totalAssetsSection')}: `, true);
        this.statusBarSectionAssetsRemaining = statusBar
            .createStatusBarSection(`${i18next.t('editorStatusBar.remainingAssetsSection')}: `, true);
        this.assignmentProgressBar.max = 1;
        this.sectionProgressBar.max = 1;
        this.editor.header.appendChild(this.assignmentProgressBar);
        document.querySelector('xfl-new-editor-section-header')
            ? sectionHeader.appendChild(this.sectionProgressBar)
            : sectionBar.appendChild(this.sectionProgressBar);
        window.addEventListener('blur', () => { this.focused = false; });
        window.addEventListener('focus', () => { this.focused = true; });
    }
    unload() {
        this.editor.content.contentEditable = "false";
        this.clearWorkTimeInterval();
        Array.from(this.duplicateUpdateTimeouts.values()).forEach(window.clearInterval);
        this.disableAssetsEdition();
        this.assignmentProgressBar.remove();
        this.sectionProgressBar.remove();
        this.editor.modules = this.editor.modules.filter(m => m != this);
    }
    disableAssetsEdition() {
        this.editor.content.querySelectorAll("xfl-new-editor-asset").forEach((a) => {
            var _a, _b;
            a.contentDiv.contentEditable = "false";
            (_a = a.querySelector("button.edit")) === null || _a === void 0 ? void 0 : _a.remove();
            (_b = a.querySelector("button.done")) === null || _b === void 0 ? void 0 : _b.remove();
        });
    }
    finishLoading() {
        var _a;
        this.setWorkTimeInterval();
        this.updateProgress(undefined, [(_a = this.editor.section) === null || _a === void 0 ? void 0 : _a.id]);
        this.editor.content.contentEditable = "true";
        this.editor.placeCursorInActiveAsset();
    }
    clearWorkTimeInterval() {
        this.workTimeInterval && window.clearInterval(this.workTimeInterval);
    }
    setWorkTimeInterval() {
        this.workTimeInterval = window.setInterval(this.reportWorkTime.bind(this), LOG_TIME_INTERVAL * 1000);
    }
    input() {
        const { activeAsset } = this.editor;
        if (!activeAsset) {
            return;
        }
        this.contentChanged(activeAsset);
        activeAsset.querySelector("button.done").disabled = !activeAsset.completable;
        const { assetId } = activeAsset;
        if (this.duplicateUpdateTimeouts.has(assetId)) {
            clearTimeout(this.duplicateUpdateTimeouts.get(assetId));
        }
        const duplicates = this.editor.getAssetDuplicates(activeAsset);
        this.duplicateUpdateTimeouts.set(assetId, window.setTimeout(() => {
            duplicates.forEach((a) => { a.currentText = activeAsset.currentText; });
            this.duplicateUpdateTimeouts.delete(assetId);
        }, duplicates.length * 10)); // Timeout duration depends on how much copies this asset has
    }
    isRecycled(assetElement) {
        return !assetElement.paid && assetElement.locked;
    }
    assetCreated(assetElement, asset = undefined) {
        if (assetElement.translationLevel == "NO_TRANSLATION") {
            return;
        }
        assetElement.contentDiv.contentEditable = this._assetEdition + '';
        if (this._assetEdition) {
            const recycledEditLabel = document.createElement("span");
            recycledEditLabel.textContent = i18next.t("editor.recycledHint");
            recycledEditLabel.className = "recycledHint";
            this.isRecycled(assetElement) && assetElement.iconBar.appendChild(recycledEditLabel);
            const editButton = document.createElement("button");
            editButton.innerHTML = i18next.t("editor.edit");
            editButton.className = "edit";
            editButton.addEventListener("click", assetElement.unlockClicked.bind(assetElement));
            assetElement.iconBar.appendChild(editButton);
            const restoreButton = document.createElement("button");
            restoreButton.innerHTML = restoreButtonIcon;
            restoreButton.className = "restore";
            restoreButton.addEventListener("click", assetElement.restoreClicked.bind(assetElement));
            const tooltip = new Popup();
            tooltip.content = i18next.t("editor.restore");
            restoreButton.appendChild(tooltip);
            restoreButton.addEventListener("mouseenter", () => tooltip.showTooltip(true));
            restoreButton.addEventListener("mouseleave", () => tooltip.showTooltip(false));
            assetElement.iconBar.appendChild(restoreButton);
            const doneButton = document.createElement("button");
            doneButton.textContent = i18next.t(assetElement.completed ? `editor.completed.${this.editor.stage}` : "editor.complete");
            doneButton.className = "done";
            doneButton.addEventListener("click", assetElement.doneClicked.bind(assetElement));
            assetElement.iconBar.appendChild(doneButton);
        }
    }
    assetActivationChanged(asset, active) {
        asset.fetchSubAssets();
        if (active) {
            this.editor.commitTimeouts.get(asset.assetId) && this.editor.commit(asset);
        }
    }
    async assetCompletionChanged(asset) {
        try {
            await this.editor.commit(asset);
            this.updateProgress(undefined, asset.sections);
        }
        catch (error) {
            this.editor.setState({ error });
        }
    }
    assetCompletabilityChanged(asset) {
        asset.querySelector("button.done").disabled = !asset.completable;
    }
    assetInstanceLockChanged(asset, locked) {
        this.updateAssetContentEditable(asset);
    }
    assetInstanceCompletionChanged(asset, completed) {
        this.updateAssetContentEditable(asset);
        const doneButton = asset.querySelector("button.done");
        if (doneButton) {
            doneButton.textContent = i18next.t(completed ? `editor.completed.${this.editor.stage}` : "editor.complete");
        }
    }
    keyDown(event) {
        var _a, _b;
        const range = window.getSelection().getRangeAt(0);
        function isSubAsset(element) {
            var _a;
            return ((_a = element) === null || _a === void 0 ? void 0 : _a.tagName) == "XFL-NEW-EDITOR-SUBASSET";
        }
        function isMacroTag(element) {
            var _a, _b;
            return ((_a = element) === null || _a === void 0 ? void 0 : _a.tagName) == "UNIVERSAL-TAG" && !!((_b = element) === null || _b === void 0 ? void 0 : _b.macro);
        }
        function precededBySubAssetOrMacroTag(node) {
            var _a;
            return ((_a = node.previousSibling) === null || _a === void 0 ? void 0 : _a.nodeType) == Node.ELEMENT_NODE
                && (isSubAsset(node.previousSibling)
                    || isMacroTag(node.previousSibling));
        }
        function followedBySubAssetOrMacroTag(node) {
            var _a;
            return ((_a = node.nextSibling) === null || _a === void 0 ? void 0 : _a.nodeType) == Node.ELEMENT_NODE
                && (isSubAsset(node.nextSibling)
                    || isMacroTag(node.nextSibling));
        }
        function isNodeStart(range) {
            return range.startOffset == 0;
        }
        function isNodeEnd(range) {
            return range.startOffset >= (range.startContainer.textContent || "").length;
        }
        function isInsideAssetContent(range) {
            return ancestorOfType(range.startContainer, "xfl-new-editor-asset").contentDiv.contains(range.startContainer);
        }
        function normalizeAssetContent(range) {
            ancestorOfType(range.startContainer, "xfl-new-editor-asset").contentDiv.normalize();
        }
        switch (event.key) {
            case "Backspace":
            case "Delete":
                if (!this.isSelectionOverwritable()) {
                    consumeEvent(event);
                    return;
                }
                if (range.collapsed && isInsideAssetContent(range)
                    && (event.key == "Backspace" && isNodeStart(range)
                        || event.key == "Delete" && isNodeEnd(range))) {
                    event.key == "Backspace" && precededBySubAssetOrMacroTag(range.startContainer)
                        && ((_a = range.startContainer.previousSibling) === null || _a === void 0 ? void 0 : _a.remove());
                    event.key == "Delete" && followedBySubAssetOrMacroTag(range.startContainer)
                        && ((_b = range.startContainer.nextSibling) === null || _b === void 0 ? void 0 : _b.remove());
                    normalizeAssetContent(range);
                    ancestorOfType(range.startContainer, "xfl-new-editor-asset").setUnusedMacroTags();
                    consumeEvent(event);
                }
                break;
        }
    }
    keyDownComplete(event) {
        const { activeAsset } = this.editor;
        switch (event.key) {
            case "Enter":
                if ((event.ctrlKey || event.metaKey) && activeAsset) {
                    if (!activeAsset.paid && activeAsset.locked) {
                        activeAsset.locked = false;
                        this.editor.placeCursorInActiveAsset();
                    }
                    else {
                        if (this.editor.isTrustMiningStage && activeAsset.assetError && !activeAsset.completed) {
                            if (activeAsset.assetErrorType) {
                                activeAsset.completed = !activeAsset.completed;
                            }
                        }
                        else if (activeAsset.translationLevel != 'NO_TRANSLATION') {
                            activeAsset.completed = !activeAsset.completed;
                        }
                        if (activeAsset.completed || activeAsset.translationLevel == 'NO_TRANSLATION') {
                            this.editor.jumpToNextUnfinishedAsset();
                            this.editor.placeCursorInActiveAsset();
                        }
                    }
                    consumeEvent(event);
                }
                break;
        }
    }
    keyPress(event) {
        if (!this.isSelectionOverwritable()) {
            consumeEvent(event);
        }
        switch (event.key) {
            case "Enter":
                consumeEvent(event);
                break;
        }
    }
    keyUp(event) {
        const range = window.getSelection().getRangeAt(0);
        try { // Firefox problem workaround
            range.startContainer.parentNode;
        }
        catch (error) {
            switch (event.key) {
                case "ArrowDown":
                    this.editor.jumpBy(1);
                    this.editor.placeCursorInActiveAsset();
                    break;
                case "ArrowUp":
                    this.editor.jumpBy(-1);
                    this.editor.placeCursorInActiveAsset(true);
            }
        }
        this.editor.updateSelectionRange();
    }
    clipboardEvent(event) {
        var _a;
        if (!this.isSelectionOverwritable()) {
            consumeEvent(event);
            return;
        }
        const { editor } = this;
        if (!editor.activeAsset)
            return;
        if (event.type === "paste" || event.type === "copy" || event.type === "cut") {
            HTTPLogger.externalAssetModification({
                type: event.type,
                assignmentId: editor.assignmentId,
                stage: editor.stage,
                totalAssets: editor.statusBar.totalAssets,
                translationLevel: editor.activeAsset.translationLevel,
                assetLength: (_a = editor.activeAsset) === null || _a === void 0 ? void 0 : _a.currentPlainText.length,
                username: UserService.getTokenUserInfo().login,
                sourceLanguage: editor.sourceLanguage,
                targetLanguage: editor.targetLanguage
            });
        }
        if (event.type == "paste") {
            const text = event.clipboardData.getData("text/plain");
            if (text && !text.includes("\n")) {
                document.execCommand('insertText', false, text);
                const { activeAsset } = this.editor;
                activeAsset && activeAsset.refresh();
            }
            consumeEvent(event);
        }
    }
    isSelectionOverwritable() {
        const selection = window.getSelection();
        return !selection
            || selection.rangeCount == 1
                && sameAsset(selection.getRangeAt(0).startContainer, selection.getRangeAt(0).endContainer);
    }
    updateAssetContentEditable(asset) {
        asset.contentDiv.contentEditable = ((asset.paid || !asset.locked) && !asset.completed).toString();
    }
    contentChanged(asset) {
        this.editor.clearCommitTimeout(asset);
        this.editor.commitTimeouts.set(asset.assetId, window.setTimeout(() => this.editor.commit(asset), 5000));
    }
    updateProgress(editorProgess = undefined, sections) {
        const { assignmentId } = this.editor;
        this.updateAssignmentProgress(editorProgess, assignmentId);
        sections.filter(sectionId => sectionId).forEach((sectionId) => {
            this.updateSectionProgress(assignmentId, sectionId);
        });
    }
    async updateAssignmentProgress(editorProgess, assignmentId) {
        try {
            const progress = editorProgess || await getProgress(assignmentId);
            const assignmentProgress = calculateProgress(progress.total, progress.completed);
            this.remainingAssignmentAssets = progress.total - progress.completed;
            this.assignmentProgressBar.value = assignmentProgress;
            this.assignmentTimeEstimated = progress.secondsRemaining;
            this.editor.modules.forEach(m => m.progressChanged(assignmentProgress));
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to update progress', error);
        }
    }
    async updateSectionProgress(assignmentId, sectionId) {
        try {
            const progress = await getJobSectionProgress(assignmentId, sectionId);
            const assetProgress = calculateProgress(progress.total, progress.completed);
            this.remainingSectionAssets = progress.total - progress.completed;
            this.totalSectionAssets = progress.total;
            this.sectionProgressBar.value = assetProgress;
            this.editor.updateSectionProgress(sectionId, progress.total, progress.completed);
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to update progress', error);
        }
    }
    async reportWorkTime() {
        if (document.visibilityState == "hidden" || !this.focused) {
            return;
        }
        try {
            await reportWorkSeconds(this.editor.assignmentId, LOG_TIME_INTERVAL);
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error('System - Failed to report work time', error);
        }
    }
    get remainingAssignmentAssets() {
        return parseInt(this.statusBarAssignmentAssetsRemaining.textContent || "");
    }
    set remainingAssignmentAssets(assets) {
        this.statusBarAssignmentAssetsRemaining.textContent = assets.toString();
    }
    get assignmentTimeEstimated() {
        return Number(this.statusBarAssignmentTimeRemaining.dataset.seconds);
    }
    set assignmentTimeEstimated(duration) {
        this.statusBarAssignmentTimeRemaining.dataset.seconds = `${duration}` || undefined;
        this.statusBarAssignmentTimeRemaining.textContent = secondsToReadableUnits(duration || 0);
    }
    get remainingSectionAssets() {
        return parseInt(this.statusBarSectionAssetsRemaining.textContent || "");
    }
    set remainingSectionAssets(assets) {
        this.statusBarSectionAssetsRemaining.textContent = assets.toString();
    }
    get totalSectionAssets() {
        return parseInt(this.statusBarSectionAssetsTotal.textContent || "");
    }
    set totalSectionAssets(assets) {
        this.statusBarSectionAssetsTotal.textContent = assets.toString();
    }
    set assetEdition(edition) {
        this._assetEdition = edition;
    }
}
