import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, TextArea, Button, Checkbox, Popup, Grid, Segment, Divider, Header } from 'semantic-ui-react';
import TemplateManager from '../templateManager/Manager';
import { getMyGatedComunities } from 'api-operations/community/member';
import { useLogger } from 'logger/Logger';
import { uploadFile, uploadZipFile } from 'api-operations/expiringFile';
import { acceptableFileFormats, acceptableFileTypes } from 'dictionaries/dictionaries';
import FilesContainer from './droparea/FilesContainer';
import FilesUpload, { acceptableZipFileTypes, isFileTypeSupported } from './droparea/FilesUpload';
const Wrapper = styled.div `
  margin-left: 1em;
  width: 100%;
`;
const StyledLabel = styled.label `
`;
const StyledInput = styled(Input) `
  width: 100%;
`;
const StyledTextArea = styled(TextArea) `
  height: 10em;
  min-height: 5em;
  max-width: 100%;
  min-width: 100%;
`;
const StyledH2 = styled.h2 `
  margin-top: 2em;
  margin-bottom: 1em;
`;
const TemplateCreationWrapper = styled.div `
  margin-top: 1em;
  & > button {
    margin-left: 1em !important;
  }
`;
const GridWrapper = styled.div `
  margin-top: 1em;
`;
const Summary = ({ setExternalProjectId, setRequesterNote, externalProjectId, requesterNote, setTestMode, testMode, genre, languageFrom, languagesTo, taxonomy, stages, manualStages, communities, isOpenCommunity, edit, files, handleRemoveFile, createJobTemplate, getValuesFromTemplate, ngo, jobName, setJobName, setAttachmentFiles, attachmentFiles }) => {
    const [templateName, setTemplateName] = useState(null);
    const [allCommunities, setAllCommunities] = useState([]);
    const roles = useSelector(state => state.userProfile.roles);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    const PROJECT_WIDTH = 10;
    const EXTERNALID_WIDTH = 6;
    useEffect(() => {
        logger.info('User - Entered summary step');
        getMyGatedCommunitiesList();
    }, []);
    const getMyGatedCommunitiesList = async () => {
        try {
            const myGCommunities = await getMyGatedComunities(true);
            setAllCommunities(myGCommunities);
        }
        catch (error) {
            //
        }
    };
    const addTemplate = async () => {
        if (templateName === null || templateName.length < 2) {
            return;
        }
        const error = await createJobTemplate(templateName);
        if (!error)
            setTemplateName('');
    };
    const uploadFiles = async (file, setProgress) => {
        return isFileTypeSupported(file, acceptableZipFileTypes)
            ? await uploadZipFile(file, setProgress)
            : await uploadFile(file, setProgress);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Segment, null,
            React.createElement(Divider, { horizontal: true },
                React.createElement(Header, { as: 'h4' }, t('jobUpload.files'))),
            React.createElement(FilesContainer, { files: files.map(f => { return { uploadedFile: f }; }), handleRemoveFile: handleRemoveFile, expectedLanguage: languageFrom, gridXs: 6, gridJustifyContent: 'flex-start', placing: 'Job input files' })),
        React.createElement(TemplateManager, { allCommunities: allCommunities, editOnly: edit, showTemplateName: false, getAllFieldsValues: getValuesFromTemplate, fieldsValues: { communities, langFrom: languageFrom, langsTo: languagesTo, genre, isOpenCommunity, manualStages, stages, taxonomy } }),
        React.createElement(TemplateCreationWrapper, null,
            React.createElement(Input, { onChange: (_, data) => setTemplateName(data.value), value: templateName, placeholder: t('jobUpload.enterJobTemplateName') }),
            React.createElement(Button, { onClick: addTemplate }, t('jobUpload.saveJobTemplate'))),
        React.createElement(StyledH2, null, t('jobUpload.additionalFields')),
        React.createElement(GridWrapper, null,
            React.createElement(Grid, null,
                React.createElement(Grid.Row, null,
                    React.createElement(Grid.Column, { stretched: true, width: 6 },
                        React.createElement(Grid, null,
                            React.createElement(Grid.Row, { columns: 16 },
                                React.createElement(Grid.Column, { width: PROJECT_WIDTH },
                                    React.createElement(StyledLabel, null,
                                        t('jobUpload.projectName'),
                                        React.createElement("span", { style: { color: 'red' } }, "*"))),
                                React.createElement(Grid.Column, { width: EXTERNALID_WIDTH },
                                    React.createElement(StyledLabel, null, t('jobUpload.externalProjectId'))),
                                React.createElement(Grid.Column, { width: PROJECT_WIDTH },
                                    React.createElement(StyledInput, { onChange: (_, data) => setJobName(data.value), type: "text", value: jobName })),
                                React.createElement(Grid.Column, { width: EXTERNALID_WIDTH },
                                    React.createElement(StyledInput, { onChange: (_, data) => setExternalProjectId(data.value), type: "text", value: externalProjectId }))),
                            React.createElement(Grid.Row, { columns: 16 },
                                React.createElement(Grid.Column, { width: 16, stretched: true },
                                    React.createElement(StyledLabel, null, t('jobUpload.requesterNote'))),
                                React.createElement(Grid.Column, { width: 16, stretched: true },
                                    React.createElement(StyledTextArea, { focus: true, onChange: (_, data) => setRequesterNote(data.value), type: "text", value: requesterNote }))))),
                    React.createElement(Grid.Column, { stretched: true, width: 6 },
                        React.createElement(Grid.Row, { stretched: true },
                            React.createElement(StyledLabel, null, t('jobUpload.attachmentFiles')),
                            React.createElement(FilesUpload, { files: attachmentFiles, fileFormats: acceptableFileFormats, fileTypes: acceptableFileTypes, setFiles: setAttachmentFiles, placing: 'Job attachment files', uploadFiles: uploadFiles }))),
                    React.createElement(Grid.Column, { width: 4 },
                        React.createElement(StyledLabel, null, "\u00A0"),
                        React.createElement(Grid.Row, null, !ngo && roles.find(role => role === 'ROLE_EXFLUENCY_ADMIN' || role === 'ROLE_COMMUNITY_MANAGER' || role === 'ROLE_PLATFORM_MANAGER') && React.createElement(Wrapper, null,
                            React.createElement(Popup, { content: t('jobUpload.testModeDescription'), trigger: React.createElement(Checkbox, { label: t('jobUpload.testMode'), checked: testMode, onChange: () => setTestMode(!testMode) }) }))))))));
};
export default Summary;
