import { commonPrefixLenght, isAlphanumeric, isShadowed } from "./utils";
import SubAssetElement from "./SubAssetElement";
export function findInNodes(nodes, textToReplace, nodeStartIndex, searchStartIndex, lang) {
    let startIndex = -1;
    let endIndex = -1;
    let foundNodeStart = -1;
    let foundNodeEnd = -1;
    for (let i = nodeStartIndex; i < nodes.length; i++) {
        const node = nodes[i];
        if (node instanceof Text) {
            const text = node.textContent || "";
            const lowerCaseText = text.toLocaleLowerCase(lang);
            const lowerCaseTextToReplace = textToReplace.toLocaleLowerCase(lang);
            const index = lowerCaseText.indexOf(lowerCaseTextToReplace, i === nodeStartIndex ? searchStartIndex : 0);
            if (index >= 0) {
                startIndex = index;
                endIndex = index + lowerCaseTextToReplace.length;
                foundNodeStart = i;
                foundNodeEnd = i;
                break;
            }
            const prefixLength = commonPrefixLenght(lowerCaseText, lowerCaseTextToReplace);
            if (prefixLength > 0) {
                let concatenatedText = lowerCaseText;
                for (let j = i + 1; j < nodes.length; j++) {
                    const nextNode = nodes[j];
                    if (nextNode instanceof Text) {
                        const nextText = nextNode.textContent || "";
                        const lowerCaseNextText = nextText.toLocaleLowerCase(lang);
                        concatenatedText += lowerCaseNextText;
                        const index = concatenatedText.indexOf(lowerCaseTextToReplace);
                        if (index >= 0) {
                            startIndex = index;
                            endIndex = index + lowerCaseTextToReplace.length - concatenatedText.length + lowerCaseNextText.length;
                            foundNodeStart = i;
                            foundNodeEnd = j;
                            break;
                        }
                        const prefixLength = commonPrefixLenght(concatenatedText, lowerCaseTextToReplace);
                        if (prefixLength === 0) {
                            break;
                        }
                    }
                }
            }
        }
    }
    if (startIndex >= 0 && endIndex >= 0 && foundNodeStart >= 0 && foundNodeEnd >= 0) {
        return {
            startNodeIndex: foundNodeStart,
            endNodeIndex: foundNodeEnd,
            startIndexInNode: startIndex,
            endIndexInNode: endIndex
        };
    }
    return null;
}
export function legacyAlgorithm(currentNode, startIndex, endIndex, subAsset, editor) {
    if (currentNode === null || currentNode.textContent === null) {
        return;
    }
    const nodeToReplace = currentNode.splitText(startIndex);
    const alphanumericBeforeTerm = isAlphanumeric(currentNode.textContent.charAt(currentNode.textContent.length - 1));
    const nodeRest = nodeToReplace.splitText(endIndex - startIndex);
    const alphanumericAfterTerm = isAlphanumeric(nodeRest.textContent.charAt(0));
    if ((!alphanumericBeforeTerm && !alphanumericAfterTerm) || isShadowed(subAsset)) {
        const subAssetElement = new SubAssetElement(subAsset, nodeToReplace.textContent || "", editor);
        nodeToReplace.replaceWith(subAssetElement);
        editor.modules.forEach(m => m.subassetCreated(subAssetElement));
    }
}
export function multipleNodesAlgorithm(nodes, startIndex, endIndex, subAsset, editor) {
    if (nodes.length < 2) {
        throw new Error("Nodes length must be greater than 1");
    }
    const [firstNode] = nodes;
    const lastNode = nodes[nodes.length - 1];
    const nodesInBetween = nodes.slice(1, nodes.length - 1);
    const firstNodeToReplace = firstNode.splitText(startIndex);
    const restNode = lastNode.splitText(endIndex);
    if (firstNodeToReplace.textContent === null) {
        return;
    }
    const tempDiv = document.createElement('span');
    appendChildNode(tempDiv, firstNodeToReplace);
    nodesInBetween.forEach((node) => {
        appendChildNode(tempDiv, node);
    });
    appendChildNode(tempDiv, lastNode);
    const alphanumericBeforeTerm = isAlphanumeric(firstNodeToReplace.textContent.charAt(firstNodeToReplace.textContent.length - 1));
    const alphanumericAfterTerm = isAlphanumeric(restNode.textContent.charAt(0));
    const isShadowedTerm = isShadowed(subAsset);
    if ((!alphanumericBeforeTerm && !alphanumericAfterTerm) || isShadowedTerm) {
        const subAssetElement = new SubAssetElement(subAsset, tempDiv, editor);
        firstNodeToReplace.replaceWith(subAssetElement);
        nodesInBetween.forEach((node) => {
            var _a;
            if (((_a = node.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'UNIVERSAL-TAG') {
                node.parentElement.remove();
            }
            else {
                node.remove();
            }
        });
        lastNode.remove();
    }
    // add element to the target with tags
    function appendChildNode(target, node) {
        var _a;
        if (((_a = node.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'UNIVERSAL-TAG') {
            target.appendChild(node.parentElement.cloneNode(true));
        }
        else {
            target.appendChild(node.cloneNode(true));
        }
    }
}
export function getNestedNodes(nodes) {
    return nodes
        .filter(node => !(node instanceof SubAssetElement))
        .map(node => node.childNodes.length > 0 ? getNestedNodes(Array.from(node.childNodes)) : node);
}
export function transformNodes(nodes, text, subAsset, editor) {
    let lastNode = 0;
    let searchStartIndex = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const result = findInNodes(nodes, text, lastNode, searchStartIndex, editor.content.lang);
        if (result === null) {
            break;
        }
        const { startNodeIndex, endNodeIndex, startIndexInNode, endIndexInNode } = result;
        lastNode = endNodeIndex;
        searchStartIndex = endIndexInNode;
        const nodesSlice = nodes.slice(startNodeIndex, endNodeIndex + 1);
        //console.log('found', textToReplace, 'in nodes', nodesSlice, 'start', startIndexInNode, 'end', endNodeIndex, 'subAsset', subAsset)
        if (startNodeIndex === endNodeIndex) {
            legacyAlgorithm(nodes[startNodeIndex], startIndexInNode, endIndexInNode, subAsset, editor);
        }
        else {
            // temporarly disabled
            // multipleNodesAlgorithm(nodesSlice, startIndexInNode, endIndexInNode, subAsset, editor)
        }
    }
}
