export default class EditorModule {
    constructor(editor) {
        this.editor = editor;
    }
    unload() { }
    finishLoading() { }
    input() { }
    assetCreated(assetElement, asset = undefined) { }
    assetActivationChanged(asset, active) { }
    assetCompletionChanged(asset, completed) { }
    assetCompletabilityChanged(asset) { }
    assetInstanceLockChanged(asset, locked) { }
    assetInstanceCompletionChanged(asset, completed) { }
    assetRefreshed(asset) { }
    assetCompletable(asset) { return true; }
    assetExtraDataReceived(asset, extraData) { }
    subassetCreated(subAsset) { }
    progressChanged(progress) { }
    selectionChanged() { }
    keyDown(event) { }
    keyDownComplete(event) { }
    keyUp(event) { }
    keyPress(event) { }
    clipboardEvent(event) { }
    clearSearchPhrase() { }
}
