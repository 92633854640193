import './style.scss';
export default class Tooltip extends HTMLElement {
    constructor() {
        super();
        this.open = false;
        this._content = document.createElement('div');
        this._content.className = 'content';
        this.appendChild(this._content);
        this.addEventListener('mouseenter', () => this.showTooltip(true));
        this.addEventListener('mouseleave', () => this.showTooltip(false));
    }
    showTooltip(show) {
        this._content.classList.toggle('active', show);
    }
    set content(value) {
        if (value instanceof HTMLElement) {
            this._content.innerHTML = '';
            this._content.appendChild(value);
            return;
        }
        this._content.innerHTML = value;
    }
}
export class Popup extends HTMLElement {
    constructor() {
        super();
        this.open = false;
        this._content = document.createElement('div');
        this._content.className = `content bottom-gray`;
        this.appendChild(this._content);
        this.addEventListener('mouseenter', () => this.showTooltip(true));
        this.addEventListener('mouseleave', () => this.showTooltip(false));
    }
    showTooltip(show) {
        this._content.classList.toggle('active', show);
    }
    set content(value) {
        if (value instanceof HTMLElement) {
            this._content.innerHTML = '';
            this._content.appendChild(value);
            return;
        }
        this._content.innerHTML = value;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-popup-ele", Popup));
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-tooltip-ele", Tooltip));
