import { IconNgBuilder } from "./icons";
import { editSvg } from "./icons/edit";
import { trashSvg } from "./icons/trash";
import { getParentNodesUntilNode, getTooltipTopPosition } from './utils';
export class AssetTooltip extends HTMLElement {
    constructor() {
        super();
        this.contentDiv = document.createElement('div');
        this.containerDiv = document.createElement('div');
        this.containerDiv.appendChild(this.contentDiv);
        this.appendChild(this.containerDiv);
    }
    connectedCallback() {
        this.contentDiv.classList.add("editorTooltip");
        this.containerDiv.classList.add("container");
        this.hideTooltip();
        this.addEventListener("mouseleave", this.hideTooltip);
    }
    showTooltip(refElement, options, event) {
        this.contentDiv.innerHTML = "";
        const groupByType = options.reduce((group, option) => {
            var _a;
            const { header } = option;
            group[header] = (_a = group[header]) !== null && _a !== void 0 ? _a : [];
            group[header].push(option);
            return group;
        }, {});
        Object.keys(groupByType).forEach((type) => {
            const headerEle = document.createElement('div');
            headerEle.className = 'header';
            headerEle.textContent = type;
            const wrapperEle = document.createElement('div');
            wrapperEle.className = 'item';
            wrapperEle.appendChild(headerEle);
            groupByType[type].forEach(({ content, onDelete, onEdit, onMouseEnter, onMouseLeave }) => {
                const contentEle = document.createElement('div');
                contentEle.className = 'content';
                const buttonsEle = document.createElement('div');
                buttonsEle.className = 'buttons';
                const text = document.createElement('div');
                text.innerHTML = content;
                contentEle.appendChild(text);
                contentEle.appendChild(buttonsEle);
                if (onEdit) {
                    new IconNgBuilder(editSvg, buttonsEle).setOnClick(onEdit).build();
                }
                if (onDelete) {
                    new IconNgBuilder(trashSvg, buttonsEle).setOnClick(() => {
                        onDelete();
                        this.hideTooltip();
                    }).build();
                }
                if (onMouseEnter) {
                    contentEle.addEventListener("mouseenter", onMouseEnter);
                }
                if (onMouseLeave) {
                    contentEle.addEventListener("mouseleave", onMouseLeave);
                }
                wrapperEle.appendChild(contentEle);
            });
            this.contentDiv.appendChild(wrapperEle);
        });
        const arr = getParentNodesUntilNode(refElement, 'content');
        arr[arr.length - 1] && this.setTooltipPosition(arr[arr.length - 1], event);
    }
    hideTooltip() {
        this.contentDiv.classList.remove('visible');
        this.contentDiv.innerHTML = "";
    }
    setTooltipPosition(el, event) {
        const rect = Array.from(el.getClientRects())
            .find(rect => event.clientX >= rect.left
            && event.clientX < rect.right
            && event.clientY >= rect.top
            && event.clientY < rect.bottom) || Array.from(el.getClientRects())[0];
        if (!rect)
            return;
        this.contentDiv.classList.add("visible");
        this.containerDiv.style.left = event.clientX - (this.contentDiv.offsetWidth / 2) - getNavbarWidth() + 'px';
        this.containerDiv.style.top = `${getTooltipTopPosition(rect.top, this.contentDiv.offsetHeight)}px`;
        this.contentDiv.style.maxWidth = `${330}px`;
        function getNavbarWidth() {
            var _a;
            return ((_a = document.getElementById("app-navbar")) === null || _a === void 0 ? void 0 : _a.clientWidth) || 0;
        }
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-tooltip", AssetTooltip));
