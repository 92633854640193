import { IconNgBuilder } from "./icons";
import { newWindowSvg } from "./icons/newWindow";
import { getNodesToText, getSanitizedHTML, textToReplace } from "./utils";
import { getNestedNodes, transformNodes } from './AssetPartition';
export default class AssetSource extends HTMLElement {
    constructor(dir, lang, editor, showWindow) {
        super();
        this.contentDiv = document.createElement("div");
        this.editor = editor;
        this.contentDiv.contentEditable = "false";
        this.contentDiv.className = "content";
        this.contentDiv.dir = dir;
        this.appendChild(this.contentDiv);
        const iconWrapper = document.createElement('div');
        iconWrapper.style.width = '32px';
        if (showWindow) {
            new IconNgBuilder(newWindowSvg, iconWrapper)
                .setOnClick(showWindow)
                .build();
        }
        this.appendChild(iconWrapper);
        this.contentEditable = "false";
        this.lang = lang;
    }
    replaceSubAssets(subAssets) {
        subAssets.forEach(this.replaceSubAsset.bind(this));
    }
    replaceSubAsset(subAsset) {
        const text = textToReplace(subAsset);
        const nodes = getNestedNodes(Array.from(this.contentDiv.childNodes))
            .flat(20)
            .filter((node) => node instanceof Text);
        transformNodes(nodes, text, subAsset, this.editor);
        this.contentDiv.normalize();
    }
    get text() {
        return getNodesToText(Array.from(this.contentDiv.childNodes));
    }
    set text(text) {
        this.contentDiv.innerHTML = getSanitizedHTML(text);
    }
    isShadowed(subAsset) {
        return subAsset.type == "ANONYMIZED" || subAsset.type == "NO_TRANSLATION";
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-asset-source", AssetSource));
