import IconButton from "editorNextGen/components/IconButton";
import TerminologyPanel from "./TerminologyPanel";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import './style.scss';
import { IconNgBuilder } from "editorNextGen/icons";
import { termsSvg } from "editorNextGen/icons/terms";
export default class TerminologyModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "TerminologyModule";
        this.terminologyPanel = new TerminologyPanel();
        this.showTerminologyButton = new IconButton("wordpress forms", i18next.t("editorHeader.showTerminologyPanel"));
        editor.leftSidePanel.appendChild(this.terminologyPanel);
        new IconNgBuilder(termsSvg, this.editor.header.toolBar)
            .setToggable(true)
            .setOnClick(() => { this.showTerminology = !this.showTerminology; })
            .isActive(true)
            .build();
    }
    unload() {
        this.terminologyPanel.remove();
        this.showTerminologyButton.remove();
    }
    keyDown() {
        this.editor.activeAsset && this.updateTerminology(this.editor.activeAsset);
    }
    input() {
        this.editor.activeAsset && this.updateTerminology(this.editor.activeAsset);
    }
    assetActivationChanged(asset, active) {
        active && this.updateTerminology(asset);
    }
    assetRefreshed(asset) {
        asset.active && this.updateTerminology(asset);
    }
    updateTerminology(asset) {
        var _a;
        const lowerCaseText = (_a = asset === null || asset === void 0 ? void 0 : asset.currentText.toLocaleLowerCase()) !== null && _a !== void 0 ? _a : "";
        this.terminologyPanel.terms = asset === null || asset === void 0 ? void 0 : asset.subAssets.filter(sa => sa.type == 'TERM' && sa.text !== null).map(sa => { var _a; return ({ "term": sa.text.toLocaleLowerCase(), "used": (_a = lowerCaseText.includes(sa.text.toLocaleLowerCase())) !== null && _a !== void 0 ? _a : false }); }).sort((a, b) => a.term.localeCompare(b.term));
    }
    get showTerminology() {
        return this.terminologyPanel.style.display == "";
    }
    set showTerminology(showTerminology) {
        this.showTerminologyButton.selected = showTerminology;
        this.terminologyPanel.style.display = showTerminology ? "" : "none";
    }
}
