import i18next from 'i18next';
export default class TerminologyPanel extends HTMLElement {
    constructor() {
        super();
        this.termList = document.createElement("ul");
        this.className = "widget-box";
        const header = document.createElement("h1");
        header.className = "widget-box--header";
        header.textContent = i18next.t('editorTerminologyPanel.termsToUse');
        this.appendChild(header);
        this.appendChild(this.termList);
        const emptyState = document.createElement("div");
        emptyState.classList.add("emptyState");
        emptyState.textContent = i18next.t('editorTerminologyPanel.noTermsInThisAsset');
        this.appendChild(emptyState);
    }
    set terms(terms) {
        this.termList.innerHTML = "";
        terms.forEach((term) => {
            const elem = document.createElement("li");
            elem.classList.toggle("missing", !term.used);
            elem.textContent = term.term;
            this.termList.appendChild(elem);
        });
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-terminology-panel", TerminologyPanel));
