import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown, Icon } from 'semantic-ui-react';
import { languagesDictionary } from 'dictionaries/dictionaries';
import { useLogger } from 'logger/Logger';
const ChooseLanguages = styled.div `
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const LanguageSelectionContainer = styled.div `
  justify-content: space-around;
  align-items: center;
`;
const LanguageRow = styled.div `
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
  min-width: 7rem;
`;
const dropdownStyle = {
    minWidth: '18rem'
};
const LanguageCombination = ({ setLanguagesTo, setLanguageFrom, langFrom, langsTo }) => {
    const [langs, setLangs] = useState([]);
    const [langsFromDict, setLangsFromDict] = useState([]);
    const [langsToDict, setLangsToDict] = useState([]);
    const { t } = useTranslation('common');
    const { logger } = useLogger();
    useEffect(() => {
        logger.info('User - Entered select language step');
        const langs = languagesDictionary.map(l => ({ key: l, value: l, text: t(`languages.${l}`) })).sort((a, b) => a.text.localeCompare(b.text));
        setLangs(langs);
        setLangsFromDict(langs.filter(lang => !(langsTo).includes(lang.key)));
        setLangsToDict(langs.filter(lang => lang.key !== langFrom));
    }, []);
    const chooseLanguage = ({ value }, which) => {
        switch (which) {
            case 'source':
                logger.info(`User - Selected source language - ${value}`);
                setLanguageFrom(value);
                setLangsToDict(langs.filter(lang => lang.key !== value));
                break;
            case 'target':
                logger.info(`User - Selected target languages - ${value} `);
                setLanguagesTo(value);
                console.log(!value.includes('pl'));
                setLangsFromDict(langs.filter(lang => !value.includes(lang.key)));
                break;
            default:
                break;
        }
    };
    return React.createElement(LanguageSelectionContainer, null,
        React.createElement("h2", null,
            " ",
            t('jobUpload.selectLanguages')),
        React.createElement(ChooseLanguages, null,
            React.createElement(LanguageRow, null,
                React.createElement("div", null, t('jobUpload.sourceLang')),
                React.createElement(Dropdown, { placeholder: t('jobUpload.sourceLangPlaceholder'), fluid: true, search: true, selection: true, options: langsFromDict, onChange: (_, { value }) => chooseLanguage({ value }, 'source'), value: langFrom, labeled: true, label: "Source language", style: dropdownStyle })),
            React.createElement(LanguageRow, null,
                React.createElement(Icon, { name: "arrow right" })),
            React.createElement(LanguageRow, null,
                React.createElement("div", null, t('jobUpload.targetLang')),
                React.createElement(Dropdown, { placeholder: t('jobUpload.targetLangPlaceholder'), fluid: true, search: true, selection: true, multiple: true, options: langsToDict, onChange: (_, { value }) => chooseLanguage({ value }, 'target'), value: langsTo, style: dropdownStyle }))));
};
export default LanguageCombination;
