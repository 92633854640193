import i18next from 'i18next';
import { getNodesToText, getParentNodesUntilNode, getSanitizedHTML, getTooltipOptionForNodes } from "./utils";
export default class SubAssetElement extends HTMLElement {
    constructor(subAsset, textToReplace, editor) {
        var _a;
        super();
        this.editor = editor;
        this.content = document.createElement("span");
        this.subAsset = subAsset;
        this.subtypes = (_a = subAsset.subtypes) !== null && _a !== void 0 ? _a : new Set();
        this.sourceText = subAsset.sourceText || null;
        this.description = subAsset.description || null;
        this.contentEditable = "false";
        this.id = subAsset.id;
        const getAnonSubtype = () => {
            return this.subtypeToToken(this.subtypes.values().next().value); //todo - handle multiple subtypes
        };
        const subassetToSpan = (text) => {
            const span = document.createElement("span");
            span.appendChild(document.createTextNode(text || ""));
            return span;
        };
        const createSubassetSpan = () => {
            var _a;
            switch (this.type) {
                case "ANONYMIZED":
                    // no anonymization for redaction if editor is not in preview mode
                    if (!(editor === null || editor === void 0 ? void 0 : editor.isAssigmentPending) && ((_a = this.editor) === null || _a === void 0 ? void 0 : _a.isRedactionStage)) {
                        return subassetToSpan(subAsset.text || "");
                    }
                    return subassetToSpan(getAnonSubtype());
                case "NO_TRANSLATION":
                    return subassetToSpan(subAsset.text || "");
                case "FORBIDDEN":
                case "TERM":
                    if (textToReplace instanceof HTMLElement) {
                        return textToReplace;
                    }
                    return subassetToSpan(textToReplace);
                default:
                    console.warn("NO-EXHAUSTIVE-MATCH for subasset type - update this function to handle new subasset types");
                    return subassetToSpan(subAsset.text || "");
            }
        };
        this.content.className = "content";
        this.content.appendChild(createSubassetSpan());
        this.appendChild(this.content);
        this.addEventListener("mouseenter", this.showTooltip.bind(this));
        this.addEventListener("mouseleave", (e) => {
            const related = e.relatedTarget;
            const tooltip = document.querySelector('.editorTooltip');
            if (related !== tooltip)
                this.getTooltip().hideTooltip();
        });
    }
    assetContentText() {
        switch (this.type) {
            case "ANONYMIZED":
            case "NO_TRANSLATION":
                return `XFL_TOKEN_${this.subAssetId}`;
            case "FORBIDDEN":
            case "TERM":
                return this.text; // Should never be null for terms
        }
        return "";
    }
    hideTooltip() {
        this.getTooltip().hideTooltip();
    }
    showTooltip(event) {
        this.getTooltip().showTooltip(this, this.getTooltipProps(event), event);
    }
    getTooltipContent() {
        let content = this.description || '';
        switch (this.subAsset.type) {
            case 'TERM':
                content = `<b>${this.subAsset.sourceText} - ${this.subAsset.text}</b><br />${this.subAsset.description ? `${this.subAsset.description}` : ''}`;
                break;
            case 'ANONYMIZED':
                content = Array.from(this.subAsset.subtypes)
                    .map(s => this.subtypeToToken(s)).join(", ");
                break;
        }
        return content;
    }
    getTooltipProps(event) {
        return this.getNestedNodes(this, event);
    }
    getNestedNodes(node, event) {
        var _a, _b, _c, _d, _e, _f;
        let arr = getParentNodesUntilNode(node, 'content');
        if (!((_b = (_a = this.editor) === null || _a === void 0 ? void 0 : _a.formattingModule) === null || _b === void 0 ? void 0 : _b.active)) {
            arr = arr.filter(node => node instanceof SubAssetElement);
        }
        const isValidStage = ["REDACTION", "FINAL_REDACTION"].includes(((_c = this.editor) === null || _c === void 0 ? void 0 : _c.stage) || '');
        const options = {
            removeSubAsset: (_d = this.editor) === null || _d === void 0 ? void 0 : _d.removeSubAsset.bind(this.editor),
            // editAnonymizedSubAsset: this.editor?.tokenEditionModule?.editAnonymizedSubAsset.bind(this.editor),
            removeFormatting: (_e = this.editor) === null || _e === void 0 ? void 0 : _e.removeFormatting.bind(this.editor),
            mouseOver: (universalTag) => {
                var _a;
                (_a = this.editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection(universalTag);
            },
            mouseLeave: () => {
                var _a;
                (_a = this.editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection();
            }
        };
        return getTooltipOptionForNodes(arr, isValidStage, (_f = this.editor) === null || _f === void 0 ? void 0 : _f.editable, event, options);
    }
    getTooltip() {
        return document.querySelector("xfl-new-editor-tooltip");
    }
    subtypeToToken(s) {
        return i18next.t(`editor.subAssets.subtypes.${s}`);
    }
    get subAssetId() {
        var _a;
        return ((_a = this.subAsset) === null || _a === void 0 ? void 0 : _a.id) || "";
    }
    set type(type) {
        this.subAsset.type = type;
    }
    get type() {
        return this.subAsset.type;
    }
    set subtypes(subtypes) {
        this.subAsset.subtypes = subtypes;
    }
    get subtypes() {
        return this.subAsset.subtypes;
    }
    set sourceText(text) {
        if (text === null) {
            this.removeAttribute('sourceText');
        }
        else {
            this.setAttribute('sourceText', text);
        }
    }
    get sourceText() {
        return this.getAttribute("sourceText");
    }
    set description(description) {
        if (description === null) {
            this.removeAttribute('description');
        }
        else {
            this.setAttribute('description', description);
        }
    }
    get description() {
        return this.getAttribute("description");
    }
    set text(text) {
        this.content.innerHTML = getSanitizedHTML(text)
            || Array.from(this.subtypes).map(subtype => i18next.t(`editor.subAssets.subtypes.${subtype}`)).join(", ")
            || i18next.t('editor.anonymized');
    }
    get text() {
        return getNodesToText(Array.from(this.content.childNodes));
    }
    get shadowed() {
        return ["ANONYMIZED", "NO_TRANSLATION"].includes(this.type);
    }
    get anonymized() {
        return this.type === "ANONYMIZED";
    }
    set inverted(invert) {
        this.toggleAttribute("inverted", !!invert);
    }
    get inverted() {
        return this.hasAttribute("inverted");
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-subasset", SubAssetElement));
