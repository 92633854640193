import { consumeEvent } from "editorNextGen/utils";
export default class Dropdown extends HTMLElement {
    constructor() {
        super();
        this.content = document.createElement("div");
        this.placeholderElement = document.createElement("span");
        this.menu = document.createElement("div");
        this.selectedOptionElement = document.createElement("div");
        this.onChangeFunc = null;
        this.options = [];
        this.content.contentEditable = "false";
        this.content.className = `ui labeled selection dropdown error`;
        this.content.tabIndex = 0;
        this.content.addEventListener("click", () => { this.open = !this.open; });
        this.content.addEventListener("blur", this.blurDropdown.bind(this));
        this.content.addEventListener("keydown", this.onKeyDown.bind(this));
        this.placeholderElement.className = "text placeholder";
        const icon = document.createElement("i");
        icon.className = "dropdown icon";
        this.menu.className = "menu";
        this.selectedOptionElement.className = "header";
        this.content.appendChild(this.placeholderElement);
        this.content.appendChild(this.selectedOptionElement);
        this.content.appendChild(icon);
        this.content.appendChild(this.menu);
        this.contentEditable = "false";
        this.selectedOption = null;
        this.appendChild(this.content);
    }
    onSelectValue(event, option) {
        this.content.classList.remove("error");
        this.selectedOption = option;
        this.open = false;
        if (this.onChange !== null) {
            this.onChange(option.value);
        }
        event.preventDefault();
        event.stopPropagation();
    }
    set open(open) {
        var _a;
        if (this.disabled) {
            return;
        }
        const dropdownY = this.content.getBoundingClientRect().y;
        const clientY = document.body.clientHeight;
        this.content.classList.toggle("active", open);
        this.content.classList.toggle("visible", open);
        this.menu.classList.toggle("transition", open);
        this.menu.classList.toggle("visible", open);
        this.menu.classList.toggle("top", clientY - dropdownY <= 200 && clientY >= 400);
        (_a = this.content.querySelector(".selected")) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ "block": "nearest" });
    }
    get open() {
        return this.content.classList.contains("visible");
    }
    set options(options) {
        this.menu.innerHTML = '';
        options.forEach((option) => {
            const description = document.createElement("span");
            description.className = "description";
            description.textContent = option.description;
            const text = document.createElement("span");
            text.className = "text";
            text.textContent = option.text;
            const item = document.createElement("div");
            item.dataset.value = option.value;
            item.className = "item";
            item.appendChild(description);
            item.appendChild(text);
            item.addEventListener("click", event => this.onSelectValue(event, option));
            this.menu.appendChild(item);
        });
    }
    get options() {
        return Array.from(this.menu.children).map(child => {
            var _a, _b;
            return ({
                description: ((_a = child.querySelector("span.description")) === null || _a === void 0 ? void 0 : _a.textContent) || "",
                text: ((_b = child.querySelector("span.text")) === null || _b === void 0 ? void 0 : _b.textContent) || "",
                value: child.dataset.value || ""
            });
        });
    }
    set selectedOption(option) {
        Array.from(this.menu.querySelectorAll('.item')).forEach((node) => {
            const isCurrent = node.dataset.value == (option === null || option === void 0 ? void 0 : option.value);
            node.classList.toggle("active", isCurrent);
            node.classList.toggle("selected", isCurrent);
        });
        this.placeholderElement.style.display = option ? "none" : "inline-block";
        this.selectedOptionElement.textContent = (option === null || option === void 0 ? void 0 : option.text) || null;
        this.selectedOptionElement.dataset.value = option === null || option === void 0 ? void 0 : option.value;
    }
    get selectedOption() {
        return this.options.find(opt => opt.value === this.selectedOptionElement.dataset.value) || null;
    }
    set value(value) {
        this.selectedOption = this.options.find(opt => opt.value == value) || null;
    }
    get value() {
        var _a;
        return ((_a = this.selectedOption) === null || _a === void 0 ? void 0 : _a.value) || null;
    }
    set onChange(onChange) {
        this.onChangeFunc = onChange;
    }
    get onChange() {
        return this.onChangeFunc;
    }
    set placeholder(placeholder) {
        this.placeholderElement.textContent = placeholder;
    }
    get placeholer() {
        return this.placeholderElement.textContent;
    }
    set error(value) {
        this.content.classList.toggle("error", value);
    }
    get error() {
        return this.content.classList.contains("error");
    }
    set disabled(value) {
        this.content.classList.toggle("disabled", value);
    }
    get disabled() {
        return this.content.classList.contains("disabled");
    }
    focusDropdown() {
        this.content.focus();
        this.open = true;
    }
    blurDropdown() {
        this.content.blur();
        this.open = false;
    }
    onKeyDown(event) {
        if (!this.selectedOption) {
            return;
        }
        const currentIndex = this.options.findIndex(opt => opt.value == this.selectedOption.value);
        switch (event.key) {
            case "ArrowDown":
                if (currentIndex < this.options.length - 1) {
                    this.selectedOption = this.options[currentIndex + 1];
                    this.content.querySelector(".selected").scrollIntoView({ "block": "nearest" });
                }
                consumeEvent(event);
                break;
            case "ArrowUp":
                if (currentIndex > 0) {
                    this.selectedOption = this.options[currentIndex - 1];
                    this.content.querySelector(".selected").scrollIntoView({ "block": "nearest" });
                }
                consumeEvent(event);
                break;
        }
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-dropdown", Dropdown));
