import { getParentNodesUntilNode, getTooltipOptionForNodes } from './utils';
export default class UniversalTagElement extends HTMLElement {
    constructor(id, nodes, forceTextFormatting) {
        super();
        this.macroElement = document.createElement("span");
        this.formattingId = '';
        this.macro = false;
        this.draggedElement = undefined;
        this.formattingId = id || this.id;
        this.id = this.formattingId;
        if (nodes === null || nodes === void 0 ? void 0 : nodes.length) {
            this.append(...nodes);
        }
        if (this.childNodes.length === 0 && !forceTextFormatting) {
            this.macro = true;
            this.contentEditable = 'false';
            this.macroElement.classList.add("macro");
            this.macroElement.textContent = this.formattingId;
            this.appendChild(this.macroElement);
            if (!this.closest('xfl-new-editor-asset-source')) {
                this.draggable = true;
                this.addDragEventListeners(this);
            }
            this.addEventListener("mouseenter", this._setMacroTagDraggableAbility.bind(this));
        }
        else {
            this.addEventListener("mouseenter", this.showTooltip.bind(this));
            this.addEventListener("mouseleave", (e) => {
                const related = e.relatedTarget;
                const tooltip = document.querySelector('.editorTooltip');
                if (related !== tooltip && !tooltip.contains(related)) {
                    this.getTooltip().hideTooltip();
                }
            });
        }
    }
    hideTooltip() {
        this.getTooltip().hideTooltip();
    }
    showTooltip(event) {
        var _a;
        event.stopPropagation();
        event.preventDefault();
        let editor;
        const asset = this.closest('xfl-new-editor-asset');
        const assetSource = this.closest('xfl-new-editor-asset-source');
        if (asset) {
            editor = asset.editor;
        }
        else if (assetSource) {
            editor = assetSource.editor;
        }
        if ((_a = editor === null || editor === void 0 ? void 0 : editor.formattingModule) === null || _a === void 0 ? void 0 : _a.active) {
            this.getTooltip().showTooltip(this, this.getTooltipProps(event), event);
        }
    }
    getChildren() {
        return !this.macro ? Array.from(this.childNodes) : [];
    }
    getTooltipProps(event) {
        return this.getNestedNodes(this, event);
    }
    getNestedNodes(node, event) {
        var _a, _b, _c;
        const arr = getParentNodesUntilNode(node, 'content');
        const options = {
            removeSubAsset: (_a = this._editor) === null || _a === void 0 ? void 0 : _a.removeSubAsset.bind(this._editor),
            // editAnonymizedSubAsset: this._editor?.tokenEditionModule?.editAnonymizedSubAsset,
            removeFormatting: (_b = this._editor) === null || _b === void 0 ? void 0 : _b.removeFormatting.bind(this._editor),
            mouseOver: (universalTag) => {
                var _a;
                (_a = this._editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection(universalTag);
            },
            mouseLeave: () => {
                var _a;
                (_a = this._editor) === null || _a === void 0 ? void 0 : _a.setFormattingSelection();
            }
        };
        return getTooltipOptionForNodes(arr, true, (_c = this._editor) === null || _c === void 0 ? void 0 : _c.editable, event, options);
    }
    _setMacroTagDraggableAbility() {
        var _a, _b;
        const isInsideSubAsset = !!this.closest('xfl-new-editor-subasset');
        this.draggable = !isInsideSubAsset && !!((_a = this._editor) === null || _a === void 0 ? void 0 : _a.editable);
        this.macroElement.classList.toggle("disabled", isInsideSubAsset || !((_b = this._editor) === null || _b === void 0 ? void 0 : _b.editable));
    }
    getTooltip() {
        return document.querySelector("xfl-new-editor-tooltip");
    }
    getAssetElement() {
        return this.closest('xfl-new-editor-asset');
    }
    compareOrder(elem1, elem2) {
        if (elem1 === elem2)
            return 0;
        if (elem1.compareDocumentPosition(elem2) & Node.DOCUMENT_POSITION_FOLLOWING) {
            return -1;
        }
        return 1;
    }
    addDragEventListeners(elem) {
        elem.addEventListener("dragstart", (e) => {
            if (e.dataTransfer)
                e.dataTransfer.effectAllowed = "move";
            const assetElement = this.getAssetElement();
            if (!assetElement)
                return;
            if (!assetElement.active)
                return;
            assetElement.dragAndDropMode = true;
            elem.classList.add('dragging');
        });
        elem.addEventListener("dragenter", () => {
            var _a, _b;
            const draggedElement = (_a = this.getAssetElement()) === null || _a === void 0 ? void 0 : _a.querySelector("universal-tag.dragging");
            if (!draggedElement)
                return;
            if (draggedElement == elem)
                return;
            const order = this.compareOrder(elem, draggedElement);
            if (!order)
                return;
            const baseElement = order === -1 ? elem : elem.nextSibling;
            (_b = elem.parentNode) === null || _b === void 0 ? void 0 : _b.insertBefore(draggedElement, baseElement);
        });
        elem.addEventListener("dragend", () => {
            elem.classList.remove('dragging');
            const assetElement = this.getAssetElement();
            assetElement === null || assetElement === void 0 ? void 0 : assetElement.indicator.remove();
            assetElement === null || assetElement === void 0 ? void 0 : assetElement.setUnusedMacroTags();
            if (assetElement) {
                assetElement.dragAndDropMode = false;
            }
        });
    }
    get nodes() {
        const nodes = [];
        nodes.push(new InescapableTextNode(`<universal-tag id="${this.formattingId}">`));
        !this.macro && nodes.push(...Array.from(this.childNodes));
        nodes.push(new InescapableTextNode('</universal-tag>'));
        return nodes;
    }
    set editor(editor) {
        this._editor = editor;
    }
    get uniqueId() {
        var _a;
        return [((_a = this.closest('xfl-new-editor-asset')) === null || _a === void 0 ? void 0 : _a.getAttribute('assetid')) || '', this.formattingId];
    }
}
export class InescapableTextNode extends Text {
}
document.addEventListener("DOMContentLoaded", () => customElements.define("universal-tag", UniversalTagElement));
