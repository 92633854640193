import { createSubAsset } from "api-operations/editor/editor";
import CreateTermModal from "./CreateTermModal";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import { v4 as uuidv4 } from 'uuid';
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { IconNgBuilder } from "editorNextGen/icons";
import { definedTermSvg } from "editorNextGen/icons/definedTerm";
import { forbiddenSvg } from "editorNextGen/icons/forbidden";
export default class TermCreationModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "TermCreationModule";
        this.createTermModal = new CreateTermModal();
        this.editor.domNode.appendChild(this.createTermModal);
        this.selectionToolsDefinedTermButton = new IconNgBuilder(definedTermSvg, this.editor.selectionTools)
            .setIconFill('stroke').setOnClick(() => this.createDefinedTerm())
            .setId('term').build();
        this.selectionToolsForbiddenPhraseButton = new IconNgBuilder(forbiddenSvg, this.editor.selectionTools)
            .setOnClick(() => this.createForbiddenTerm())
            .setId('forbidden').build();
    }
    unload() {
        this.createTermModal.remove();
        this.selectionToolsDefinedTermButton.remove();
        this.selectionToolsForbiddenPhraseButton.remove();
    }
    createDefinedTerm(range = this.editor.selectionTools.lastRange) {
        if (!range)
            return;
        this.createTermModal.header = i18next.t("editor.createTermModal.definedTerm");
        this.createTermModal.descriptionLabelText = i18next.t("editor.createTermModal.explanation");
        this.createTermModal.open = true;
        this.createTermModal.callback = () => {
            const subAsset = {
                id: uuidv4(),
                type: "TERM",
                text: range.toString(),
                sourceText: this.createTermModal.sourceText,
                description: this.createTermModal.description,
                subtypes: new Set()
            };
            this.editor.insertSubAsset(range, subAsset);
            this.createTerm(subAsset);
        };
    }
    createForbiddenTerm(range = this.editor.selectionTools.lastRange) {
        if (!range)
            return;
        this.createTermModal.header = i18next.t("editor.createTermModal.forbiddenPhrase");
        this.createTermModal.descriptionLabelText = i18next.t("editor.createTermModal.justification");
        this.createTermModal.open = true;
        this.createTermModal.callback = () => {
            const subAsset = {
                id: uuidv4(),
                type: "FORBIDDEN",
                text: range.toString().toLocaleLowerCase(this.editor.content.lang),
                sourceText: this.createTermModal.sourceText,
                description: this.createTermModal.description,
                subtypes: new Set()
            };
            this.editor.insertSubAsset(range, subAsset);
            this.createTerm(subAsset);
        };
    }
    async createTerm(subAsset) {
        var _a;
        try {
            const newSubAsset = {
                "id": subAsset.id,
                "assetId": subAsset.assetId,
                "type": subAsset.type,
                "sourceText": subAsset.sourceText,
                "text": subAsset.text,
                "description": subAsset.description,
                "subtypes": Array.from(((_a = subAsset.subtypes) === null || _a === void 0 ? void 0 : _a.values()) || [])
            };
            await createSubAsset(this.editor.assignmentId, newSubAsset);
            HTTPLogger.info(`User - Created subAsset - ${subAsset.type}`);
            this.editor.commit(this.editor.activeAsset);
            if (["TERM", "FORBIDDEN"].includes(subAsset.type)) {
                const assets = Array.from(this.editor.content.querySelectorAll("xfl-new-editor-asset"));
                setTimeout(() => {
                    this.editor.fetchSubAssets(assets.map(e => e.assetId)); // in some cases don't update assets properly
                }, 1000);
            }
        }
        catch (error) {
            this.editor.setState({ error });
            HTTPLogger.error(`User - Failed to create subAsset - ${subAsset.type}`, error);
        }
    }
}
