import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dropdown, Icon, Popup, Table } from 'semantic-ui-react';
import PagedJobsCompanyView from "./PagedJobsCompanyView";
import { Styled } from 'StyledComponents';
import { parseTimestamp } from 'helpers/DateHandler';
import CompanyJobInfo from './CompanyJobInfo';
import Avatar from 'helpers/components/Avatar';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { getCompanyExpenses, getCompanyExpensesCsv } from 'api-operations/company/admin';
import JobAssignmentInfo from 'dashboards/shared/JobAssignmentInfo';
import PriceWithExchangeRatePopup from 'dashboards/shared/PriceWithExchangeRatePopup';
import JobNamePopup from 'dashboards/shared/JobNamePopup';
import store from 'reduxStore/store';
const ActionButtonsWrapper = styled.div `
  display: flex;
  flex-direction: row;
`;
const CompanyJobs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [expandedRows, setExpandedRows] = useState([]);
    const [isError, setIsError] = useState(false);
    const [paginationOptions, setPaginationOptions] = useState({});
    const { exchangeRate } = store.getState().company;
    const { t } = useTranslation('common');
    const params = useParams();
    const fetchData = async (paginationFilterOptions) => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getCompanyExpenses(params.id, paginationFilterOptions);
            setJobs(response.results);
            setAllResultsCount(response.totalCount);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    const downloadCsv = async () => {
        try {
            await getCompanyExpensesCsv(params.id, paginationOptions);
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleRowExpand = (jobId) => {
        const isExpanded = expandedRows.includes(jobId);
        setExpandedRows(prevState => isExpanded ? prevState.filter(id => id !== jobId) : prevState.concat(jobId));
    };
    const rowCanBeExpanded = (stages) => {
        if (stages === undefined)
            return false;
        const assignments = stages
            .map(stage => stage.assignments.length)
            .reduce((prev, current) => {
            return prev + current;
        });
        return assignments !== 0;
    };
    const orderOptions = [
        { key: 'DUD', text: t('pagination.filter.uploadDateD'), value: 'uploadDate DESCENDING' },
        { key: 'AUD', text: t('pagination.filter.uploadDateA'), value: 'uploadDate ASCENDING' },
        { key: 'FD', text: t('pagination.filter.fileNameD'), value: 'fileName DESCENDING' },
        { key: 'FA', text: t('pagination.filter.fileNameA'), value: 'fileName ASCENDING' },
        { key: 'SD', text: t('pagination.filter.statusD'), value: 'status DESCENDING' },
        { key: 'SA', text: t('pagination.filter.statusA'), value: 'status ASCENDING' },
        { key: 'LFD', text: t('pagination.filter.langFromD'), value: 'langFrom DESCENDING' },
        { key: 'LFA', text: t('pagination.filter.langFromA'), value: 'langFrom ASCENDING' },
        { key: 'TFD', text: t('pagination.filter.langToD'), value: 'langTo DESCENDING' },
        { key: 'TFA', text: t('pagination.filter.langToA'), value: 'langTo ASCENDING' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, null, t('dashboard.finishedJobs')),
        React.createElement(PagedJobsCompanyView, { orderOptions: orderOptions, totalResults: allResultsCount, fetchData: fetchData, setPaginationOptions: setPaginationOptions, tableIdentifier: 'CompanyExpenses' },
            React.createElement(Table, { celled: true, selectable: true, striped: true, color: 'green' },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, { singleLine: true },
                            React.createElement(Dropdown, { as: Button, compact: true, floating: true, color: 'blue', text: 'Actions' },
                                React.createElement(Dropdown.Menu, null,
                                    React.createElement(Dropdown.Item, { onClick: () => downloadCsv() },
                                        React.createElement(Icon, { name: "download" }),
                                        t('dashboard.downloadCsv'))))),
                        React.createElement(Table.HeaderCell, null, t('dashboard.requester')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.externalProjectId')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.projectName')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.languages')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.deliveryDate')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.totalCost')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.actions')))),
                isLoading || isError || !jobs.length ? React.createElement(TablePlaceholder, { isError: isError, isLoading: isLoading, noEntries: !jobs.length })
                    : React.createElement(Table.Body, null, jobs.map(job => React.createElement(React.Fragment, { key: job.id },
                        React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, null, rowCanBeExpanded(job.stages) && React.createElement(Button, { size: 'mini', icon: expandedRows.includes(job.id) ? "angle up" : "angle down", onClick: () => handleRowExpand(job.id) })),
                            React.createElement(Table.Cell, null, job.requester),
                            " ",
                            React.createElement(Table.Cell, null, job.externalProjectId),
                            " ",
                            React.createElement(Table.Cell, null,
                                React.createElement(JobNamePopup, { name: job.fileName })),
                            " ",
                            React.createElement(Table.Cell, null,
                                t(`languages.${job.langFrom.toLowerCase()}`),
                                " ",
                                React.createElement(Icon, { name: "arrow right" }),
                                ' ',
                                " ",
                                t(`languages.${job.langTo.toLowerCase()}`)),
                            " ",
                            React.createElement(Table.Cell, null, job.deliveryDate ? parseTimestamp(job.deliveryDate) : job.status === 'CANCELLED' ? t(`jobStatus.${job.status}`) : ''),
                            " ",
                            React.createElement(Table.Cell, null,
                                React.createElement(PriceWithExchangeRatePopup, { cost: job.totalCost, customCurrencyCost: job.companyCurrencyCost, exchangeRate: exchangeRate })),
                            " ",
                            React.createElement(Table.Cell, null,
                                React.createElement(ActionButtonsWrapper, null,
                                    job.requesterNote && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, on: ['hover', 'click'], content: job.requesterNote, trigger: (React.createElement(Button, { icon: "sticky note outline" })) }),
                                    React.createElement(CompanyJobInfo, { job: job })))),
                        expandedRows.includes(job.id) && React.createElement(Table.Row, null,
                            React.createElement(Table.Cell, { colSpan: "11" }, job.stages.length !== 1 && React.createElement(Table, { celled: true, selectable: true, striped: true },
                                React.createElement(Table.Header, null,
                                    React.createElement(Table.Row, null,
                                        React.createElement(Table.HeaderCell, null, t('dashboard.executor')),
                                        React.createElement(Table.HeaderCell, null, t('dashboard.deliveryDate')),
                                        React.createElement(Table.HeaderCell, null, t('dashboard.projectType')),
                                        React.createElement(Table.HeaderCell, null, t('dashboard.estimatedPrice')),
                                        React.createElement(Table.HeaderCell, null, t('dashboard.projectStatus')),
                                        React.createElement(Table.HeaderCell, null, t('dashboard.actions')))),
                                React.createElement(Table.Body, null, job.stages.map(stage => stage.assignments.length == 0 ? null : stage.assignments
                                    .map(assignment => React.createElement(Table.Row, { key: assignment.id },
                                    React.createElement(Table.Cell, { singleLine: true },
                                        React.createElement(Avatar, { name: assignment.executor, inlineAvatar: true }),
                                        " ",
                                        assignment.executor),
                                    React.createElement(Table.Cell, null, assignment.deliveryDate && parseTimestamp(assignment.deliveryDate)),
                                    " ",
                                    React.createElement(Table.Cell, null,
                                        React.createElement(Popup, { on: 'click', pinned: true, trigger: React.createElement("p", null, t(`dashboard.${stage.stageType}`)), content: `AssignmentId: ${assignment.id}` })),
                                    " ",
                                    React.createElement(Table.Cell, null,
                                        React.createElement(PriceWithExchangeRatePopup, { cost: assignment.cost, customCurrencyCost: assignment.companyCurrencyCost, exchangeRate: exchangeRate })),
                                    " ",
                                    React.createElement(Table.Cell, null, t(`assignmentStatus.${assignment.assignmentStatus}`)),
                                    " ",
                                    React.createElement(Table.Cell, null,
                                        React.createElement(ActionButtonsWrapper, null,
                                            React.createElement(JobAssignmentInfo, { assignment: assignment }))))))))))))))));
};
export default CompanyJobs;
