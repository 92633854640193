import AssetSource from "editorNextGen/AssetSource";
import { IconNgBuilder } from "../../icons";
import { cancelSvg } from "../../icons/cancel";
import './style.scss';
const ASSET_SOURCE_WINDOW_STORAGE_KEY = 'asset-source-window-';
export default class AssetSourceWindow extends HTMLElement {
    constructor(dir, lang, editor) {
        super();
        this._header = document.createElement('div');
        this._headerText = document.createElement('span');
        this._headerText.textContent = 'Source text';
        this._headerText.className = 'text';
        this._header.className = 'header';
        this._header.appendChild(this._headerText);
        new IconNgBuilder(cancelSvg, this._header).setOnClick(this.closeWindow.bind(this)).build();
        this._assetSource = new AssetSource(dir, lang, editor);
        this.appendChild(this._header);
        this.appendChild(this._assetSource);
        this._setupWindowParameters();
        this._handleMove = this._move.bind(this);
        this._handleStopMove = this._stopMove.bind(this);
        this._header.addEventListener('mousedown', this._initMove.bind(this), false);
        this._observeElemementResize();
    }
    set contentText(text) {
        this._assetSource.text = text;
    }
    set subAssets(subAssets) {
        this._assetSource.replaceSubAssets(subAssets);
    }
    set onCloseCallback(onClose) {
        this._onClose = onClose;
    }
    set open(open) {
        this.classList.toggle('open', open);
    }
    get open() {
        return this.classList.contains('open');
    }
    closeWindow() {
        this.open = false,
            this._onClose && this._onClose();
    }
    /** Set element with width, height and left, top position form local storage or default values if did not exist */
    _setupWindowParameters() {
        this.style.width = `${this._getWidth()}px`;
        this.style.height = `${this._getHeight()}px`;
        this.style.top = `${this._getPositionTop()}px`;
        this.style.left = `${this._getPositionLeft()}px`;
    }
    /** Default value if not provided from local storage: 500 */
    _getWidth() {
        const width = localStorage.getItem(ASSET_SOURCE_WINDOW_STORAGE_KEY + 'width');
        return width !== null ? Number(width) : 500;
    }
    /** Default value if not provided from local storage: 508 */
    _getHeight() {
        const height = localStorage.getItem(ASSET_SOURCE_WINDOW_STORAGE_KEY + 'height');
        return height !== null ? Number(height) : 508;
    }
    /** Default value if not provided from local storage: 16 */
    _getPositionLeft() {
        const left = localStorage.getItem(ASSET_SOURCE_WINDOW_STORAGE_KEY + 'position-left');
        return left !== null ? Number(left) : 16;
    }
    /** Default value if not provided from local storage: 196 */
    _getPositionTop() {
        const top = localStorage.getItem(ASSET_SOURCE_WINDOW_STORAGE_KEY + 'position-top');
        return top !== null ? Number(top) : 196;
    }
    /** Add event listeners to handle element drag */
    _initMove(e) {
        e.preventDefault();
        if (e.target != this._header && e.target != this._headerText)
            return; // prevent drag when on close button
        window.addEventListener('mousemove', this._handleMove, false);
        window.addEventListener('mouseup', this._handleStopMove, false);
    }
    /** Change element position */
    _move(e) {
        const newLeft = e.clientX - (this._header.offsetWidth / 2);
        const newTop = e.clientY - (this._header.offsetHeight / 2);
        if (newLeft > 5 && window.innerWidth - (this.offsetWidth / 2) - 10 > e.clientX) {
            this.style.left = `${newLeft}px`;
            this._saveValue('position-left', newLeft);
        }
        if (newTop > 10 && window.innerHeight - (this.offsetHeight) > e.clientY) {
            this.style.top = `${newTop}px`;
            this._saveValue('position-top', newTop);
        }
    }
    /** Remove event listeners set in _initMove method */
    _stopMove() {
        window.removeEventListener('mousemove', this._handleMove, false);
        window.removeEventListener('mouseup', this._handleStopMove, false);
    }
    /** Upate element width and height on change in local storage */
    _observeElemementResize() {
        const resizeObserver = new ResizeObserver((entries) => {
            const { height, width } = entries[0].contentRect;
            if (height && width) {
                this._saveValue('height', height);
                this._saveValue('width', width);
            }
        });
        resizeObserver.observe(this);
    }
    /** Save value to local storage */
    _saveValue(key, value) {
        localStorage.setItem(ASSET_SOURCE_WINDOW_STORAGE_KEY + key, `${value}`);
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-asset-source-window", AssetSourceWindow));
